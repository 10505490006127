<template>
<v-main>
  <v-container>
    <v-card>
      <v-card-text>
        <FileUpload></FileUpload>
      </v-card-text>
    </v-card>
  </v-container>
</v-main>
</template>

<script>
import FileUpload from "@/components/FileUpload";
export default {
  name: "Test",
  components: { FileUpload }
};
</script>

<style scoped>

</style>